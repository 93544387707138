import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordModalComponent } from './password-modal/password-modal.component';
import { ModalsService } from '@kuki/tv/modals/modals.service';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { PinModalComponent } from './pin-modal/pin-modal.component';
import { DirectivesModule } from '@kuki/global/shared/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '@kuki/tv/features/form/form.module';
import { ActionsModalComponent } from './actions-modal/actions-modal.component';
import { ButtonsPanelModule } from '@kuki/global/features/buttons-panel/buttons-panel.module';
import { ActionsModalV2Component } from './actions-modal-v2/actions-modal-v2.component';

@NgModule({
    declarations: [
        PasswordModalComponent,
        ConfirmModalComponent,
        PinModalComponent,
        ActionsModalComponent,
        ActionsModalV2Component
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        TranslateModule,
        FormModule,
        DirectivesModule,
        ButtonsPanelModule
    ],
    providers: [
        { provide: 'ModalsService', useClass: ModalsService },
    ]
})
export class ModalsModule {
}
