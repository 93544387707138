import { NgZone, Renderer2 } from '@angular/core';
import { MediaPlayerHalInterface } from '@kuki/global/features/media-player/media-player-hals/media-player-hal.interface';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { NavigatorService } from '@kuki/global/shared/services/navigator.service';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { AudioTrack, SubtitleTrack } from '@kuki/global/shared/types/general';
import { MediaPlayerError, MediaPlayerErrors } from '@kuki/global/shared/types/media-player';
import { hal } from '@kuki/platforms/hal';
import { BehaviorSubject, fromEvent, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, take } from 'rxjs/operators';
import { PlatformHal } from '../../../../../../platforms/platform-hal';

declare var shaka;

export class ShakaPlayerV2Service implements MediaPlayerHalInterface {
    public readonly hevc: boolean = true;
    public readonly nativeBuffer = false;
    private readonly TRACKS_POLL_INTERVAL_VALUE: number = 500;
    private readonly KUKI_CAST_CHANNEL: string = 'urn:x-cast:cz.kuki.v2.cast.AudioTracks';

    private _player: any;
    private _video: HTMLVideoElement;
    private renderer: Renderer2;
    private castProxy: any;
    private airplayVideo: HTMLVideoElement;
    private audioTracks: Array<AudioTrack> = [];
    private subtitleTracks: Array<SubtitleTrack> = [];
    private nativeAudioTracks: any = [];
    private nativeSubtitleTracks: any = [];
    private activeAudioTrack: AudioTrack;
    private activeSubtitleTrack: SubtitleTrack;
    private lockAudioChange: boolean;
    private sessionKey: string;
    private tracksPollInterval: any;

    public buffering$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public end$: Subject<void> = new Subject<void>();
    public error$: Subject<MediaPlayerError> = new Subject<MediaPlayerError>();
    public tracksUpdated$?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public trackActivated$?: ReplaySubject<void> = new ReplaySubject<void>(1);
    public switchLayout$: Subject<AudioTrack> = new Subject<AudioTrack>();
    public chromecastAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public chromecastConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public airplayAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public airplayConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public requestAirplayUrl$: ReplaySubject<void> = new ReplaySubject<void>(1);
    public videoStartedNew$: ReplaySubject<void> = new ReplaySubject<void>(1);

    // 7000 - LOAD_INTERRUPTED: The call to Player.load() was interrupted by a call to Player.unload() or another call to Player.load()
    // 7001 - OPERATION_ABORTED: An internal error which indicates that an operation was aborted. This should not be seen by applications
    // 1003 - TIMEOUT: A network request timed out.
    // 20 (DOMException) - AbortError: The play() request was interrupted by a new load request
    private readonly INGORE_ERROR_CODES = [ 7000, 7001, 1003, 20 ];

    private subscription: SubscriptionObject = {};

    constructor(
        private platformHalService: PlatformHal,
        private portalSettingsService: PortalSettingsService,
        private navigatorService: NavigatorService,
        private ngZone: NgZone) {
    }

    public init(videoElement: HTMLVideoElement, renderer: Renderer2) {
        shaka.polyfill.installAll();
        this.renderer = renderer;

        // shaka.log.setLevel(shaka.log.Level.V2);

        // Check to see if the browser supports the basic APIs Shaka needs.
        if (shaka.Player.isBrowserSupported()) {
            // Everything looks good!
            this.initPlayer(videoElement);
            if (hal.mediaPlayer.chromecastReceiverAppId) {
                this.initChromecast();
            }
            if (hal.mediaPlayer.airplay) {
                this.initAirPlay();
            }
        } else {
            // This browser does not have the minimum set of APIs we need.
            console.error('Browser not supported!');
        }
    }

    public play(streamUrl: string, params: any = {}): Promise<void> {
        this.buffering$.next(true);
        // if (hal.platform === 'TV.TIZEN') {
        //     this._player.resetAbrEstimates();
        // }
        if (this.chromecastConnected$.getValue()) {
            this.player.load(streamUrl)
                .catch((e) => {
                    if (this.onError(e)) {
                        this.buffering$.next(false);
                        throw e;
                    }
                });
            // Load event from shaka doest not resolve...
            console.log('Remote video has now been loaded!');
            this.buffering$.next(false);
            this.startTracksPolling();
            return Promise.resolve();
        }
        if (this.airplayConnected$.getValue()) {
            this.requestAirplayUrl$.next();
            return Promise.resolve();
        }
        if (params.forceMute) {
            this._video.muted = true;
        }
        return this._player.load(streamUrl, undefined, params.forceFormat ? params.forceFormat : undefined)
            .then(() => {
                // This runs if the asynchronous load is successful.
                console.log('The video has now been loaded!');
                this.startTracksPolling();
                // Begin playback, since autoplay is not enabled on the video tag.
                const playPromise = this._video.play();
                // if browser doesnt support play as promise, only resolve
                if (playPromise) {
                    return playPromise.catch((e) => {
                        // TODO: Debug aborting on tizen and webos
                        if (e.name === 'NotAllowedError') {
                            this.error$.next({ code: MediaPlayerErrors.AUTOPLAY_FAILED, detail: e });
                        } else if (this.navigatorService.isSafari()) {
                            this.error$.next({ code: MediaPlayerErrors.AUTOPLAY_FAILED, detail: e });
                        }
                        return;
                    });
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                this.videoStartedNew$.next();
                this.buffering$.next(false);
            })
            .catch((e) => {
                if (this.onError(e)) {
                    this.buffering$.next(false);
                    throw e;
                }
            });  // onError is executed if the asynchronous load fails.
    }

    public pause() {
        return new Promise((resolve) => {
            this.buffering$.next(false);
            if (this.video) {
                this.video.pause();
            }
            if (this.airplayVideo) {
                this.airplayVideo.pause();
            }
            resolve();
        });
    }

    public stop() {
        this.buffering$.next(false);
        this.stopTracksPolling();
        if (!this.player) {
            return Promise.reject({ code: MediaPlayerErrors.MEDIA_PLAYER_STOP_ERROR });
        }
        return this.player.unload();
    }

    public activateAudioTrack(id: number) {
        if (this.lockAudioChange) {
            return;
        }
        const audioTrack = this.audioTracks.find(audioTrackItem => audioTrackItem.id === id);
        if (!audioTrack) {
            return;
        }
        const differentLayout =
            (!this.activeAudioTrack && audioTrack.layout === '5.1')
            || (this.activeAudioTrack && audioTrack.layout !== this.activeAudioTrack.layout);
        this.activeAudioTrack = audioTrack;
        // has to be here, otherwise switchLayout doesn't work
        this.player.selectAudioLanguage(audioTrack.language, audioTrack.layout);
        this.trackActivated$.next();
        if (differentLayout) {
            this._player.configure('preferredAudioChannelCount', audioTrack.layout === '5.1' ? 6 : 2);
            this._player.configure('preferredVariantRole', audioTrack.layout);
            this._player.configure('preferredAudioLanguage', audioTrack.language);
            this.lockAudioChange = true;
            this.tracksUpdated$.next(false);
            this.switchLayout$.next(audioTrack);
        }
    }

    public activateAudioTrackByLayout(layout: string) {
        const audioTrack = this.audioTracks.find(audioTrackItem => audioTrackItem.layout === layout);
        if (!audioTrack) {
            return;
        }
        this.player.selectAudioLanguage(audioTrack.language, audioTrack.layout);
    }

    public activateAudioTrackByLanguageAndLayout(language: string, layout: string) {
        this.lockAudioChange = false;
        const audioTrack = this.audioTracks.find(audioTrackItem =>
            audioTrackItem.language === language && audioTrackItem.layout === layout);
        if (!audioTrack) {
            return;
        }
        this.activeAudioTrack = audioTrack;
        this.trackActivated$.next();
        this.player.selectAudioLanguage(audioTrack.language, audioTrack.layout);
    }

    public activateSubtitleTrack(id: number) {
        if (id !== undefined) {
            const subtitleTrack = this.subtitleTracks.find(subtitleTrackItem => subtitleTrackItem.id === id);
            if (!subtitleTrack) {
                return;
            }
            this.player.selectTextLanguage(subtitleTrack.language, subtitleTrack.type);
            this.player.setTextTrackVisibility(true);
            this.activeSubtitleTrack = subtitleTrack;
        } else {
            this.player.setTextTrackVisibility(false);
            this.activeSubtitleTrack = undefined;
        }
        this.trackActivated$.next();
    }

    public refreshTracks() {
        // reset layout to default
        this.activateAudioTrackByLayout('2.0');
        if (this._player) {
            this._player.configure('preferredAudioChannelCount', 2);
            this._player.configure('preferredVariantRole', '');
            this._player.configure('preferredAudioLanguage', '');
        }
        this.audioTracks = [];
        this.subtitleTracks = [];
        this.nativeAudioTracks = [];
        this.nativeSubtitleTracks = [];
        this.lockAudioChange = false;
        this.activeAudioTrack = undefined;
        this.activeSubtitleTrack = undefined;
        this.tracksUpdated$.next(false);
    }

    public requestChromecast() {
        if (!this.castProxy) {
            return;
        }
        if (this.castProxy.isCasting()) {
            this.castProxy.suggestDisconnect();
        } else {
            this.castProxy.cast().then(() => {
                this.chromecastConnected$.next(true);
                this.startTracksPolling(true);
                const chromecastSession = this.castProxy.getCastSender().getCastSession();
                // !!! listen zde musi byt, klidne prazdne.
                // Aby to fungovalo, protoze receiver s tim kanalem pracuje kvuli mobilu !!!
                chromecastSession.addMessageListener(this.KUKI_CAST_CHANNEL, (channel: string, audioTracks: string) => {
                });
            }).catch((e) => console.error('error', e));
        }
    }

    public requestAirplay() {
        if ((window as any).WebKitPlaybackTargetAvailabilityEvent) {
            if (!this.airplayVideo) {
                this.createAirPlayVideoEl();
                this.requestAirplayUrl$.next();
            } else {
                (this.airplayVideo as any).webkitShowPlaybackTargetPicker();
            }
        }
    }

    public playAirplay(streamUrl: string) {
        if (!this.airplayVideo) {
            return;
        }
        this.airplayVideo.src = streamUrl;
        this.buffering$.next(false);
        this.subscription.loadedData = fromEvent(this.airplayVideo, 'loadeddata')
            .pipe(take(1), delay(1000)).subscribe(() => {
                (this.airplayVideo as any).webkitShowPlaybackTargetPicker();
            });
    }

    public getAudioTracks(): Array<AudioTrack> {
        return this.audioTracks;
    }

    public getSubtitleTracks(): Array<SubtitleTrack> {
        return this.subtitleTracks;
    }

    public getActiveAudioTrack(): AudioTrack {
        return this.activeAudioTrack;
    }

    public getActiveSubtitleTrack(): SubtitleTrack {
        return this.activeSubtitleTrack;
    }

    public setSessionKey(sessionKey: string): void {
        this.sessionKey = sessionKey;
    }

    public getPlayerDebug() {
        if (!this.player) {
            return Promise.resolve('');
        }
        const s = this.player.getStats();
        const bi = this.player.getBufferedInfo();
        if (!s || !bi) {
            return Promise.resolve('');
        }
        let dbg = `BW:${ Math.round(s.estimatedBandwidth / 1024) || '?' } / ${ Math.round(s.streamBandwidth / 1024) || '?' } ` +
            `BT:${ Math.round(s.bufferingTime * 100) / 100 || '?' } DF:${ s.droppedFrames || '?' }`;
        if (bi.total.length >= 1) {
            dbg += ` BI:${ Math.round(bi.total[ 0 ].start) || '?' }-` +
                `${ Math.round(this.player.getMediaElement().currentTime) || '?' }-` +
                `${ Math.round(bi.total[ 0 ].end) || '?' } (${ bi.total.length || '?' })`;
        }
        return Promise.resolve(dbg);
    }

    public getPlayerVersion() {
        return shaka.Player.version;
    }

    public getVolume(): number {
        return this.video?.volume || 0;
    }

    public getMuted(): boolean {
        return this.video?.muted || false;
    }

    public setVolume(volume: number) {
        if (this.video) {
            this.video.volume = volume;
        }
    }

    public setMute(muted: boolean) {
        if (this.video) {
            this.video.muted = muted;
        }
    }

    public destroy() {
        this.storeEstimatedBandwidth();
        this.stopTracksPolling();
        if (this._player) {
            this._player.removeEventListener('error', this.onErrorListener);
            // this._player.removeEventListener('bufferingStart', this.onBufferingStartListener);
            // this._player.removeEventListener('bufferingEnd', this.onBufferingEndListener);
            this._player.destroy().catch(e => {
                this.onError(e);
            });
        }
        if (this.castProxy) {
            this.castProxy.removeEventListener('caststatuschanged', this.chromecastStatusListener);
        }
        if (this._video) {
            this._video.removeEventListener('webkitplaybacktargetavailabilitychanged', this.airplayAvaibilityListener);
        }
        if (this.airplayVideo) {
            this.airplayVideo.removeEventListener('webkitcurrentplaybacktargetiswirelesschanged', this.airplayConnectionListener);
        }
        SOM.clearSubscriptionsObject(this.subscription);
        this.subscription = {};

        this._player = undefined;
        this._video = undefined;
        this.renderer = undefined;
        this.castProxy = undefined;
        this.airplayVideo = undefined;
        this.audioTracks = [];
        this.subtitleTracks = [];
        this.activeAudioTrack = undefined;
        this.activeSubtitleTrack = undefined;
        this.nativeAudioTracks = [];
        this.nativeSubtitleTracks = [];
        this.lockAudioChange = false;

        this.buffering$ = new BehaviorSubject(true);
        this.error$ = new Subject<MediaPlayerError>();
        this.tracksUpdated$ = new BehaviorSubject(false);
        this.trackActivated$ = new ReplaySubject<void>(1);
        this.switchLayout$ = new Subject<AudioTrack>();
        this.chromecastAvailable$ = new BehaviorSubject(false);
        this.chromecastConnected$ = new BehaviorSubject(false);
        this.airplayAvailable$ = new BehaviorSubject(false);
        this.airplayConnected$ = new BehaviorSubject(false);
        this.requestAirplayUrl$ = new ReplaySubject(1);
        this.videoStartedNew$ = new ReplaySubject<void>(1);
    }

    private initPlayer(videoElement) {
        this._video = videoElement;
        this._player = new shaka.Player(this._video);
        // does not work in shaka production build!
        // shaka.log.setLevel(shaka.log.Level.INFO);

        // Attach player to the window to make it easy to access in the JS console.
        (window as any).player = this._player;

        // Listen for error events.
        this._player.addEventListener('error', this.onErrorListener);
        // this._player.addEventListener('bufferingStart', this.onBufferingEndListener);
        // this._player.addEventListener('bufferingEnd', this.onBufferingEndListener);
        this._video.addEventListener('ended', this.onEndListener);

        const portalSettings = this.portalSettingsService.getPortalSettings();
        const configuration = {
            abr: {
                enabled: true,
                defaultBandwidthEstimate: this.loadEstimatedBandwidth()
            },
            manifest: {
                availabilityWindowOverride: 60,
                retryParameters: {
                    timeout: 6000,
                },
                hls: {
                    autoCorrectDrift: false
                },
            },
            streaming: {
                bufferBehind: 15,
                rebufferingGoal: 3,
                bufferingGoal: 40,
                retryParameters: {
                    timeout: 15000,
                },
                startAtSegmentBoundary: true,
                bufferAfterLiveEdge: true,
                ignoreGapsSmallerThan: 5,
                browserGapTolerance: 2
            },
            preferredAudioChannelCount: 2
        };
        if (this.platformHalService.getFeatures().wv) {
            configuration[ 'drm' ] = {
                servers: {
                    'com.widevine.alpha': portalSettings.mediaPlayer.widevineProxy + '?sessionKey=' + this.sessionKey
                },
                advanced: {
                    'com.widevine.alpha': {
                        'videoRobustness': 'SW_SECURE_CRYPTO',
                        'audioRobustness': 'SW_SECURE_CRYPTO'
                    }
                },
                retryParameters: {
                    timeout: 6000,
                },
            };
        }
        this._player.configure(configuration);
    }

    private initChromecast() {
        this.castProxy = new shaka.cast.CastProxy(this._video, this._player, hal.mediaPlayer.chromecastReceiverAppId);
        (window as any).castProxy = this.castProxy;
        this.castProxy.addEventListener('caststatuschanged', this.chromecastStatusListener);
    }

    private initAirPlay() {
        if ((window as any).WebKitPlaybackTargetAvailabilityEvent) {
            this._video.addEventListener('webkitplaybacktargetavailabilitychanged', this.airplayAvaibilityListener);
        }
        this.subscription.airplayConnected = this.airplayConnected$.pipe(debounceTime(1000)).subscribe((connected) => {
            if (!connected && this.airplayVideo) {
                this.airplayVideo.removeEventListener('webkitcurrentplaybacktargetiswirelesschanged', this.airplayConnectionListener);
                this.renderer.removeChild(this.renderer.parentNode(this._video), this.airplayVideo);
                this.airplayVideo = undefined;
            } else if (connected) {
                if (!this.airplayVideo) {
                    this.createAirPlayVideoEl();
                }
                this.video.pause();
                this.airplayVideo.play();
            }
        });
    }

    private startTracksPolling(activateTracks: boolean = false) {
        if (!this.tracksPollInterval) {
            this.ngZone.runOutsideAngular(() => {
                this.tracksPollInterval = setInterval(() => {
                    this.prepareAudioTracks();
                    this.prepareSubtitleTracks();
                    if (this.nativeAudioTracks.length > 0) {
                        this.stopTracksPolling();
                        if (this.tracksUpdated$.getValue()) {
                            if (activateTracks) {
                                if (this.activeAudioTrack) {
                                    this.activateAudioTrack(this.activeAudioTrack.id);
                                }
                                if (this.activeSubtitleTrack) {
                                    this.activateSubtitleTrack(this.activeSubtitleTrack.id);
                                }
                            }
                        } else {
                            this.tracksUpdated$.next(true);
                        }
                    }
                }, this.TRACKS_POLL_INTERVAL_VALUE);
            });
        }
    }

    private prepareAudioTracks() {
        const audioTracks: Array<AudioTrack> = [];
        this.nativeAudioTracks = this.player.getAudioLanguagesAndRoles();
        for (let i = 0; i < this.nativeAudioTracks.length; i++) {
            const track = this.nativeAudioTracks[ i ];
            const audioTrack = {
                id: i,
                label: track.label,
                language: track.language,
                layout: track.role
            };
            audioTracks.push(audioTrack);
        }
        this.audioTracks = audioTracks.sort((a, b) => a.id > b.id ? 1 : -1);
    }

    private prepareSubtitleTracks() {
        const subtitleTracks: Array<AudioTrack> = [];
        this.nativeSubtitleTracks = this.player.getTextTracks();
        for (let i = 0; i < this.nativeSubtitleTracks.length; i++) {
            const track = this.nativeSubtitleTracks[ i ];
            if (subtitleTracks.some(item => item.language === track.language)) {
                continue;
            }
            const subtitleTrack = {
                id: i,
                label: track.label,
                language: track.language,
                type: track.role
            };
            subtitleTracks.push(subtitleTrack);
        }
        this.subtitleTracks = subtitleTracks.sort((a, b) => a.id > b.id ? 1 : -1);
    }

    private stopTracksPolling() {
        if (this.tracksPollInterval) {
            clearInterval(this.tracksPollInterval);
            this.tracksPollInterval = undefined;
        }
    }

    private createAirPlayVideoEl() {
        this.airplayVideo = this.renderer.createElement('video');
        // no attributes like autoplay, controls needed, because they are boolean - false if not included
        this.airplayVideo.addEventListener('webkitcurrentplaybacktargetiswirelesschanged', this.airplayConnectionListener);
        this.renderer.appendChild(this.renderer.parentNode(this._video), this.airplayVideo);
    }

    // getters
    private get player() {
        if (!this.castProxy) {
            return this._player;
        }
        return this.castProxy.getPlayer();
    }

    private get video() {
        if (!this.castProxy) {
            return this._video;
        }
        return this.castProxy.getVideo();
    }

    private onError(error, async: boolean = false): any {
        // drm error starts with 6
        if (error.code && +(error.code.toString()[ 0 ]) === 6) {
            this.error$.next({ code: MediaPlayerErrors.DRM_FAILED, detail: error, async: async });
            return error;
        }
        // Log the error.
        if (error.code !== undefined && this.INGORE_ERROR_CODES.indexOf(error.code) === -1) {
            this.error$.next({ code: MediaPlayerErrors.MEDIA_PLAYER_HAL_ERROR, detail: error, async: async });
            return error;
        }
    }

    private onErrorListener = (error) => {
        // Extract the shaka.util.Error object from the event.
        this.onError(error.detail, true);
    };

    private onEndListener = () => {
        this.end$.next();
    };

    private onBufferingStartListener = () => {
        this.buffering$.next(true);
    };

    private onBufferingEndListener = () => {
        this.buffering$.next(false);
    };

    private chromecastStatusListener = () => {
        if (this.castProxy.canCast()) {
            this.chromecastAvailable$.next(true);
        } else {
            this.chromecastAvailable$.next(false);
        }

        if (this.castProxy.isCasting()) {
            this.chromecastConnected$.next(true);
        } else {
            this.chromecastConnected$.next(false);
        }
    };

    private airplayAvaibilityListener = (e: any) => {
        this.videoStartedNew$.pipe(take(1)).subscribe(() => {
            switch (e.availability) {
                case 'available':
                    this.airplayAvailable$.next(true);
                    break;
                case 'not-available':
                    this.airplayAvailable$.next(false);
                    break;
            }
        });
    };

    private airplayConnectionListener = (e) => {
        if (this.airplayConnected$.getValue()) {
            this.airplayConnected$.next(false);
        } else {
            this.airplayConnected$.next(true);
        }
    };

    private storeEstimatedBandwidth() {
        if (this.player) {
            const s = this.player.getStats();
            localStorage?.setItem('shakaEstimatedBandwidth', s.estimatedBandwidth);
        }
    }

    private loadEstimatedBandwidth() {
        const estimatedBandwidth = localStorage?.getItem('shakaEstimatedBandwidth');
        return estimatedBandwidth ? +estimatedBandwidth : undefined;
    }
}
