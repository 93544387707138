import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenSaverComponent } from './screen-saver.component';
import { RatingModule } from '@kuki/global/features/rating/rating.module';
import { AutoScrollContainerModule } from '@kuki/global/shared/modules/auto-scroll-container/auto-scroll-container.module';
import { ScreenSaverService } from '@kuki/tv/features/screen-saver/screen-saver.service';


@NgModule({
    declarations: [ ScreenSaverComponent ],
    imports: [
        CommonModule,
        RatingModule,
        AutoScrollContainerModule
    ],
    exports: [
        ScreenSaverComponent
    ],
    providers: [
        ScreenSaverService
    ]
})
export class ScreenSaverModule {
}
