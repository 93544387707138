<ng-container *ngIf="isReady()">
    <app-info-panel></app-info-panel>
    <div class="main" #main>
        <router-outlet *ngIf="isRendered()"></router-outlet>
    </div>
    <app-channel-switcher></app-channel-switcher>
    <app-volume></app-volume>
    <app-mouse-control *appPlatform="'TV.WEBOS'"></app-mouse-control>
    <app-section-arrows></app-section-arrows>
    <ng-container *ngIf="isBeta()">
        <app-beta-label *appVisible="'beta-label'"></app-beta-label>
    </ng-container>
    <div class="right-top-container">
        <app-time *appVisible="'time'"></app-time>
        <app-npvr-stats *appVisible="'npvr-stats'"></app-npvr-stats>
        <ng-container *appVisible="'profile-icon'">
            <app-profile-icon *ngIf="activeProfile"
                              [profile]="activeProfile"></app-profile-icon>
        </ng-container>
    </div>
    <app-screen-saver *ngIf="screenSaverVisible"></app-screen-saver>
</ng-container>
<app-notifications></app-notifications>
