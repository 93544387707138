import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Media } from '@kuki/global/shared/types/media';
import { InfoPanelService } from '@kuki/tv/features/info-panel/info-panel.service';
import { ImageLinkFactoryService } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.service';
import { SOM, SubscriptionObject } from '@kuki/global/shared/others/subscription/subscription-object';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { ImageTypes, MediaStates, TileTypes } from '@kuki/global/shared/types/enum';
import { DeviceTeleportTile } from '@kuki/global/shared/types/tile';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { MediaPlayerV2Component } from '@kuki/global/features/media-player/media-player-v2/media-player-v2.component';
import { catchError, skip, switchMap, tap } from 'rxjs/operators';
import { of, timer } from 'rxjs';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { mediaPlayerConfig } from '@kuki/tv/features/info-panel/info-panel.config';
import { ScreenSaverService } from '@kuki/tv/features/screen-saver/screen-saver.service';
import { RestrictionService } from '@kuki/global/shared/services/restriction.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-info-panel',
    templateUrl: './info-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoPanelComponent implements OnInit, OnDestroy {
    @ViewChild(MediaPlayerV2Component, { static: false }) mediaPlayerComponent: MediaPlayerV2Component;
    private readonly VIDEO_PREVIEW_DELAY = 3000;

    public TileTypes = TileTypes;
    public ImageTypes = ImageTypes;
    public videoPreview: boolean;
    public media: Media;
    public small: boolean;
    public left: boolean;
    private subscription: SubscriptionObject = {};
    public visible: boolean = true;

    constructor(
        private infoPanelService: InfoPanelService,
        private coreService: CoreService,
        private translateService: TranslateService,
        private imageLinkFactoryService: ImageLinkFactoryService,
        private screenSaverService: ScreenSaverService,
        private restrictionService: RestrictionService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private tileService: TileService,
        private settingsService: SettingsService,
        private changeDetectorRef: ChangeDetectorRef) {
    }


    ngOnInit() {
        this.subscription.loaded = this.infoPanelService.loaded$
            .pipe(
                switchMap((media) => this.setupInfoPanel(media))
            ).subscribe();

        this.subscription.visibilityChanged = this.infoPanelService.visibilityChanged$.subscribe((visible) => {
            this.visible = visible;
        });

        this.subscription.screenSaver = this.screenSaverService.onScreenSaverShow$
            .pipe(
                skip(1),
                switchMap((visible) => {
                    if (visible) {
                        if (this.videoPreview) {
                            this.videoPreview = false;
                            this.changeDetectorRef.detectChanges();
                        }
                    } else {
                        return this.setupInfoPanel();
                    }
                    return of(undefined);
                })
            ).subscribe();

        this.subscription.sizeChanged = this.infoPanelService.sizeChanged$.subscribe((size) => {
            if (size === 'small') {
                this.small = true;
                this.renderer.addClass(this.elementRef.nativeElement, 'info-panel-small');
            } else {
                this.small = false;
                this.renderer.removeClass(this.elementRef.nativeElement, 'info-panel-small');
            }
        });

        this.subscription.posChanged = this.infoPanelService.posChanged$.subscribe((pos) => {
            if (pos === 'left') {
                this.left = true;
                this.renderer.addClass(this.elementRef.nativeElement, 'info-panel-left');
            } else {
                this.left = false;
                this.renderer.removeClass(this.elementRef.nativeElement, 'info-panel-left');
            }
        });
    }

    private setupInfoPanel(media = this.media) {
        this.videoPreview = false;
        if (this.visible) {
            this.media = media;
        } else {
            this.media = null;
        }
        if (this.media && this.media.genre && this.media.genre.length > 3) {
            this.media.genre = [ ...this.media.genre.slice(0, 3), '...' ];
        }
        this.changeDetectorRef.detectChanges();
        if (!this.media) {
            this.clearBackdrop();
        } else {
            this.generateBackdrop();
            if (this.restrictionService.isSessionPlayRestricted()) {
                return of(undefined);
            }
            const videoPreview = this.settingsService.getParsedSettingsValue<boolean>('videoPreview');
            if (videoPreview) {
                if (this.media.previewUrl ||
                    (this.media.tile.state === MediaStates.LIVE && !this.tileService.isPlayRestricted(this.media.tile))) {
                    return timer(this.VIDEO_PREVIEW_DELAY)
                        .pipe(
                            switchMap(() => this.generateVideo()),
                            tap(() => {
                                this.clearBackdrop();
                            }),
                            catchError((error) => {
                                this.videoPreview = false;
                                this.changeDetectorRef.detectChanges();
                                return of(undefined);
                            })
                        );
                }
            }
        }
        return of(null);
    }

    private generateBackdrop() {
        if (!this.media.backdrop) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', 'none');
            return;
        }
        const type = 'backdrop';
        switch (this.media.tile.type) {
            case TileTypes.MEDIA:
                const mediaImageType = this.imageLinkFactoryService.getImageTypeFromMediaType(this.media.tile.mediaType);
                const mediaUrl = `url(${ this.imageLinkFactoryService.generateUrl(mediaImageType, type, this.media.tile.image, this.media.tile.imageDefault) })`;
                this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', mediaUrl);
                break;
            case TileTypes.BUTTON:
                const buttonImageType = this.media.tile.tileRowGuid === 'settings' ? ImageTypes.SETTINGS : ImageTypes.BUTTON;
                const buttonUrl = `url(${ this.imageLinkFactoryService.generateUrl(buttonImageType, 'backdrop', this.media.backdrop) })`;
                this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', buttonUrl);
                break;
            case TileTypes.DEVICE_TELEPORT:
                const deviceImageType = ImageTypes.DEVICE;
                const deviceUrl = `url(${ this.imageLinkFactoryService.generateUrl(deviceImageType, 'backdrop', this.media.tile.backdrop) })`;
                this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', deviceUrl);
                break;
            case TileTypes.PROFILE:
                const profileImageType = ImageTypes.PROFILE_IMAGE;
                const profileUrl = `url(${ this.imageLinkFactoryService.generateUrl(profileImageType, 'backdrop', this.media.tile.backdrop) })`;
                this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', profileUrl);
                break;
            case TileTypes.CARROUSEL:
                const carrouselImageType = ImageTypes.CARROUSEL;
                const carrouselUrl = `url(${ this.imageLinkFactoryService.generateUrl(carrouselImageType, 'backdrop', this.media.tile.backdrop) })`;
                this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', carrouselUrl);
                break;
        }
    }

    private clearBackdrop() {
        this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', 'none');
    }

    private generateVideo() {
        this.videoPreview = true;
        this.changeDetectorRef.detectChanges();
        const profileType = this.coreService.getActiveProfileType() || 'classic';
        const config = {
            ...mediaPlayerConfig[ profileType ],
            forceMute: true,
            onlyPlay: true
        };
        if (this.media.tile?.channelId) {
            if (this.media.tile.state === MediaStates.LIVE) {
                return this.mediaPlayerComponent.mediaPlayerV2Service.playLive(this.media.tile.channelId, config);
            }
        } else if (this.media.previewUrl) {
            return this.mediaPlayerComponent.mediaPlayerV2Service.playUrl(this.media.previewUrl, {
                ...config,
                forceFormat: 'video/mp4'
            });
        }
        return of(undefined);
    }

    public getDeviceTeleportTile() {
        return this.media.tile as DeviceTeleportTile;
    }

    public getDeviceTeleportState() {
        return this.getDeviceTeleportTile().online ?
            this.translateService.instant('GENERAL.DEVICE_STATE.ONLINE') : this.translateService.instant('GENERAL.DEVICE_STATE.OFFLINE');
    }

    public getLabelDeviceStateClass() {
        if (!this.getDeviceTeleportTile().online) {
            return 'label-device-state-off';
        }
    }

    ngOnDestroy() {
        SOM.clearSubscriptionsObject(this.subscription);
    }
}
