import { ErrorHandler, NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '@kuki/environments/environment';
import { AuthService } from '@kuki/global/sections/auth/auth.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthModule } from '@kuki/global/sections/auth/auth.module';
import { MediaModule } from '@kuki/global/shared/modules/media/media.module';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { NavigationService } from '@kuki/global/shared/services/navigation.service';
import { WSService } from '@kuki/global/shared/services/ws.service';
import { hal } from '@kuki/platforms/hal';
import { SearchService } from '@kuki/global/shared/services/search.service';
import { LoaderModule } from '@kuki/global/shared/modules/loader/loader.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigatorService } from '@kuki/global/shared/services/navigator.service';
import { ShoppingModule } from '@kuki/global/shared/modules/shopping/shopping.module';
import { NotificationModule } from '@kuki/global/shared/modules/notification/notification.module';
import { GeneralService } from '@kuki/global/shared/services/general.service';
import { PasswordResolveService } from '@kuki/global/shared/services/password-resolve.service';
import { DeviceService } from '@kuki/global/shared/services/device.service';
import { TimeProgressService } from '@kuki/global/shared/services/time-progress.service';
import { BetaLabelModule } from '@kuki/global/features/beta-label/beta-label.module';
import { ScrollService } from '@kuki/global/shared/services/scroll.service';
import * as Sentry from '@sentry/browser';
import { ControllerService } from '@kuki/global/shared/services/controller.service';
import { services } from '@kuki/platforms/services';
import { ChannelSwitcherModule } from '@kuki/global/features/channel-switcher/channel-switcher.module';
import { VisibleModule } from '@kuki/global/shared/modules/visible/visible.module';
import { NpvrStatsModule } from '@kuki/global/features/npvr-stats/npvr-stats.module';
import { ConnectionCheckService } from '@kuki/global/shared/services/connection-check.service';
import { LoggingService } from '@kuki/global/shared/services/logging.service';
import { ErrorInterceptor } from '@kuki/global/shared/error/error.interceptor';
import { WatchDogService } from '@kuki/global/shared/services/watch-dog.service';
import { UpgraderService } from '@kuki/global/shared/services/upgrader.service';
import { CacheMapService } from '@kuki/global/shared/services/cache-map.service';
import { plugins } from '@kuki/platforms/plugins';
import { PowerControlService } from '@kuki/global/shared/services/power-control.service';
import { ChannelService } from '@kuki/global/shared/services/channel.service';
import { TileService } from '@kuki/global/shared/services/tile.service';
import { RestrictionService } from '@kuki/global/shared/services/restriction.service';
import { SessionService } from '@kuki/global/shared/services/session.service';
import { ParentalControlService } from '@kuki/global/shared/services/parental-control.service';
import { SettingsService } from '@kuki/global/shared/services/settings.service';
import { PortalSettingsService } from '@kuki/global/shared/services/portal-settings.service';
import { TagService } from '@kuki/global/shared/services/tag.service';
import { WatchedService } from '@kuki/global/shared/services/watched.service';
import { ImageLinkFactoryModule } from '@kuki/global/shared/modules/image-link-factory/image-link-factory.module';
import { HistoryService } from '@kuki/global/shared/services/history.service';
import { StartupService } from '@kuki/global/shared/services/startup.service';
import { SelfTestModule } from '@kuki/global/shared/modules/self-test/self-test.module';
import { CacheControlService } from '@kuki/global/shared/services/cache-control.service';
import { SectionControlModule } from '@kuki/tv/shared/modules/section-control/section-control.module';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { CoreService } from '@kuki/global/shared/services/core.service';
import { ComponentRegisterService } from '@kuki/global/shared/services/component-register.service';
import { WsLogService } from '@kuki/global/shared/services/ws-log.service';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { ArrisPlatformHalService } from '@kuki/platforms/tv/arris/arris-platform-hal.service';
import { DeviceTypes } from '@kuki/global/shared/types/device';
import { SharedAndroidPlatformHalService } from '@kuki/platforms/shared/android-platform-hal.service';
import { ProfileService } from '@kuki/global/shared/services/profile.service';
import { ProfileGuardService } from '@kuki/global/shared/services/profile-guard.service';
import { Angulartics2Module } from 'angulartics2';
import { ProfilePasswordResolveService } from '@kuki/global/shared/services/profile-password-resolve.service';
import { PinWInterceptor } from './global/shared/services/pin-w.interceptor';
import { PreferredChannelTrackService } from './global/shared/services/preferred-channel-track.service';
import { TeleportService } from './global/shared/services/teleport.service';
import { ShakaPlayerV2Service } from '@kuki/global/features/media-player/media-player-hals/shaka-player/shaka-player-v2.service';
import { KreaTVPlayerV2Service } from '@kuki/global/features/media-player/media-player-hals/kreatv-player/kreatv-player-v2.service';
import { ExoPlayerV2Service } from '@kuki/global/features/media-player/media-player-hals/exo-player/exo-player-v2.service';
import { TizenPlayerV2Service } from '@kuki/global/features/media-player/media-player-hals/tizen-player/tizen-player-v2.service';
import { TizenPlatformHalService } from '@kuki/platforms/tv/tizen/tizen-platform-hal.service';
import { MessagingService } from '@kuki/global/shared/services/messaging.service';
import { TruncatePipe } from '@kuki/global/shared/pipes/truncate.pipe';
import { GlobalErrorHandler } from '@kuki/global/shared/error/global-error-handler';
import { CarrouselService } from '@kuki/global/shared/services/carrousel.service';
import { ShakaPlayerEmbeddedService } from '@kuki/global/features/media-player/media-player-hals/shaka-player-embedded/shaka-player-embedded.service';
import { BroadcastGapsService } from '@kuki/global/shared/services/broadcast-gaps.service';
import { IosPlatformHalService } from '../platforms/mobile/ios/ios-platform-hal.service';

const sentryDsn = hal.platform === 'TV.ARRIS' ? environment.sentry.dsnStb : environment.sentry.dsn;
if (sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        integrations: [
            new Sentry.Integrations.TryCatch({
                XMLHttpRequest: false,
            }),
        ],
        ignoreErrors: [
            'Non-Error exception captured',
            'Non-Error promise rejection captured',
            'Failed to fetch',
            'NetworkError',
            'NotAllowedError',
            'Uncaught (in promise): shaka.util.Error', // temporary until update shaka to newest version,
            'vc_request_action is not defined', // Samsung TV Browser
            'Cannot read property \'_avast_submit\' of undefined', // Avast extension error,
            'ChunkLoadError',
            'ResizeObserver loop limit exceeded'
        ],
        denyUrls: [ /scripts.*\.js$/ ]
    });
    Sentry.setTag('version', environment.version);
    Sentry.setTag('versionBuild', environment.versionBuild);
}

export function jwtOptionsFactory(authService: AuthService) {
    return {
        tokenGetter: () => {
            return authService.getAuthData()?.session_key;
        },
        authScheme: '',
        headerName: 'X-SessionKey',
        allowedDomains: environment.whitelistedDomains,
        disallowedRoutes: environment.blacklistedRoutes
    };
}

// // AoT requires an exported function for factories
// export function createTranslateLoader(http: HttpClient) {
//     return new TranslateHttpLoader(http, './assets-out/i18n/', '.json');
// }

export class LazyTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return from(import(`../assets-out/i18n/${ lang }.json`));
    }
}

export const mediaPlayerHalFactory = (
    generalService: GeneralService,
    platformHalService: PlatformHal,
    cacheMapService: CacheMapService,
    portalSettingsService: PortalSettingsService,
    httpClient: HttpClient,
    componentRegisterService: ComponentRegisterService,
    navigatorService: NavigatorService,
    ngZone: NgZone) => {
    if (hal.deviceType === DeviceTypes.WEB) {
        return new ShakaPlayerV2Service(platformHalService, portalSettingsService, navigatorService, ngZone);
        // return new ShakaPlayerService(portalSettingsService, notificationService, translateService, navigatorService, ngZone);
    }
    switch (hal.platform) {
        case 'TV.ARRIS':
            return new KreaTVPlayerV2Service(platformHalService as ArrisPlatformHalService, cacheMapService, httpClient, ngZone);
        case 'TV.ANDROID':
        case 'MOBILE.ANDROID':
            return new ExoPlayerV2Service(platformHalService as SharedAndroidPlatformHalService, portalSettingsService);
        case 'MOBILE.IOS':
            return new ShakaPlayerEmbeddedService(platformHalService as IosPlatformHalService, portalSettingsService);
        case 'TV.TIZEN':
            return generalService.isTizen2015() ?
                new TizenPlayerV2Service(
                    platformHalService as TizenPlatformHalService,
                    generalService, cacheMapService, componentRegisterService, httpClient, ngZone) :
                new ShakaPlayerV2Service(platformHalService, portalSettingsService, navigatorService, ngZone);
        case 'TV.WEBOS':
        case 'WEB':
            return new ShakaPlayerV2Service(platformHalService, portalSettingsService, navigatorService, ngZone);
    }
};

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        AuthModule,
        MediaModule,
        ShoppingModule,
        LoaderModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [ AuthService ]
            }
        }),
        NgbModalModule,
        NgxPageScrollCoreModule.forRoot(
            {
                duration: 400,
                scrollOffset: 80
            }
        ),
        // cant be indexOf/some - some WTF bug after build
        // TODO: check if indexOf works in newer angular version
        hal.platform === 'TV.TIZEN' || hal.platform === 'TV.ARRIS' ? NoopAnimationsModule : BrowserAnimationsModule,
        NotificationModule.forRoot(),
        BetaLabelModule,
        ChannelSwitcherModule,
        NpvrStatsModule,
        VisibleModule.forRoot(),
        ImageLinkFactoryModule.forRoot(),
        SelfTestModule,
        SectionControlModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: LazyTranslateLoader,
                deps: [ HttpClient ]
            }
        }),
        Angulartics2Module.forRoot()
    ],
    providers: [
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: 'PlatformHalService', useClass: services.platformHalServiceType },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: PinWInterceptor, multi: true },
        {
            provide: 'MediaPlayerHalService', useFactory: mediaPlayerHalFactory, deps: [
                GeneralService,
                'PlatformHalService',
                CacheMapService,
                PortalSettingsService,
                HttpClient,
                ComponentRegisterService,
                NavigatorService,
                NgZone
            ]
        },
        CoreService,
        ControllerService,
        NavigationService,
        TileService,
        ChannelService,
        PortalSettingsService,
        SessionService,
        RestrictionService,
        ParentalControlService,
        SettingsService,
        WSService,
        SearchService,
        NavigatorService,
        GeneralService,
        DeviceService,
        TimeProgressService,
        PasswordResolveService,
        ProfilePasswordResolveService,
        ScrollService,
        ConnectionCheckService,
        LoggingService,
        TagService,
        WatchedService,
        WatchDogService,
        UpgraderService,
        CacheMapService,
        PowerControlService,
        HistoryService,
        StartupService,
        CacheControlService,
        CarrouselService,
        ComponentRegisterService,
        WsLogService,
        ProfileService,
        ProfileGuardService,
        TeleportService,
        MessagingService,
        BroadcastGapsService,
        TruncatePipe,
        PreferredChannelTrackService,
        ...plugins,
    ],
    exports: [
        BrowserModule,
        NotificationModule,
        BetaLabelModule,
        ChannelSwitcherModule,
        VisibleModule,
        NpvrStatsModule
    ]
})
export class AppSharedModule {
}
