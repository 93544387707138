import { BroadcastGap } from '@kuki/global/shared/types/general';
import { environment } from '@kuki/environments/environment';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

export interface Interval {
    from: number;
    to: number;
}

@Injectable()
export class BroadcastGapsService {
    private readonly FETCH_OFFSET_BEFORE = 2 * 60 * 60 * 1000; // 2 hours
    private readonly FETCH_OFFSET_AFTER = 2 * 60 * 60 * 1000; // 2 hours
    private readonly CACHE_LIVE_OFFSET = 2 * 60 * 60 * 1000; // 2 hours
    private readonly CACHE_LIVE_FETCH_LIMIT = 60 * 1000; // 1 minute
    private readonly CACHE_LENGTH_LIMIT_PER_CHANNEL = 20;
    private broadcastIntervals: { [ key: number ]: Array<Interval> } = {};
    private broadcastGaps: { [ key: number ]: Array<BroadcastGap> } = {};
    private broadcastIntervalsMargins: { [ key: number ]: Interval } = {};
    private lastTimeHit: { [ key: number ]: number } = {};

    constructor(private httpClient: HttpClient) {
    }

    public getActiveGapsAtTime(channelId: number, time: number): Observable<Array<BroadcastGap>> {
        this.lastTimeHit[ channelId ] = time;
        const liveOffset = Date.now() - this.CACHE_LIVE_OFFSET;
        const obs$ = !this.isCached(channelId, time) ?
            this.fetchBroadcastGaps(channelId, this.generateInterval(time, liveOffset), liveOffset) : of(undefined);
        return obs$.pipe(
            map(() => this.broadcastGaps[ channelId ] ?
                this.broadcastGaps[ channelId ].filter(item =>
                    item.datetimeFrom <= time && item.datetimeTo > time &&
                    // validity, can be null
                    (!item.validFrom || item.validFrom <= time) && (!item.validTo || item.validTo >= time)
                ) : []
            )
        );
    }

    private storeBroadcastGaps(channelId: number, broadcastGaps: Array<BroadcastGap>) {
        if (broadcastGaps.length === 0) {
            return;
        }
        this.broadcastGaps[ channelId ] = this.broadcastGaps[ channelId ] || [];
        const index = this.broadcastGaps[ channelId ].findIndex(item => item.datetimeFrom < broadcastGaps[ 0 ].datetimeFrom);
        this.broadcastGaps[ channelId ].splice(index, 0, ...broadcastGaps);
    }

    private storeBroadcastInterval(channelId: number, interval: Interval) {
        this.broadcastIntervals[ channelId ] = this.broadcastIntervals[ channelId ] || [];
        if (this.broadcastIntervals[ channelId ].length === 0) {
            this.broadcastIntervals[ channelId ] = [ interval ];
            this.broadcastIntervalsMargins[ channelId ] = {
                from: interval.from,
                to: interval.to
            };
            return;
        }
        let intervals = [];
        for (const broadcastInterval of this.broadcastIntervals[ channelId ]) {
            // before
            if (interval.from < broadcastInterval.from && interval.to < broadcastInterval.to) {
                intervals = [ ...intervals, interval, broadcastInterval ];
                break;
            }
            // over OR same
            if (interval.from <= broadcastInterval.from && interval.to >= broadcastInterval.to) {
                intervals = [ ...intervals, interval ];
                break;
            }
            // inside
            if (interval.from > broadcastInterval.from && interval.to < broadcastInterval.to) {
                intervals = [ ...intervals, broadcastInterval ];
                break;
            }
            // cross left
            if (interval.from <= broadcastInterval.from && interval.to > broadcastInterval.from) {
                intervals = [ ...intervals, { from: interval.from, to: broadcastInterval.to } ];
                break;
            }
            // cross right
            if (interval.from <= broadcastInterval.to && interval.to > broadcastInterval.to) {
                intervals = [ ...intervals, { from: broadcastInterval.from, to: interval.to } ];
                break;
            }
            // after
            if (interval.from > broadcastInterval.to) {
                intervals = [ ...intervals, broadcastInterval, interval ];
                break;
            }
        }
        this.broadcastIntervals[ channelId ] = intervals;
        if (interval.from < this.broadcastIntervalsMargins[ channelId ].from) {
            this.broadcastIntervalsMargins[ channelId ].from = interval.from;
        }
        if (interval.to > this.broadcastIntervalsMargins[ channelId ].to) {
            this.broadcastIntervalsMargins[ channelId ].to = interval.to;
        }
    }

    private generateInterval(time: number, liveOffset: number) {
        if (time >= liveOffset) {
            return {
                from: liveOffset - this.FETCH_OFFSET_BEFORE,
                to: liveOffset,
            };
        }
        return {
            from: time - this.FETCH_OFFSET_BEFORE,
            to: Math.min(time + this.FETCH_OFFSET_AFTER, liveOffset)
        };
    }

    private getFetchInterval(channelId: number, interval: Interval): Interval {
        if (this.broadcastIntervals[ channelId ]) {
            for (const broadcastInterval of this.broadcastIntervals[ channelId ]) {
                // inside
                if (interval.from >= broadcastInterval.from && interval.to <= broadcastInterval.to) {
                    return;
                }
                // over
                if (interval.from < broadcastInterval.from && interval.to > broadcastInterval.to) {
                    return interval;
                }
                // cross left
                if (interval.from < broadcastInterval.from && interval.to >= broadcastInterval.from) {
                    return { from: interval.from, to: broadcastInterval.from };
                }
                // cross right
                if (interval.from <= broadcastInterval.to && interval.to > broadcastInterval.to) {
                    return { from: broadcastInterval.to, to: interval.to };
                }
            }
        }
        return interval;
    }

    private checkLiveIntervalDuration(interval: Interval, liveOffset: number) {
        if (!interval) {
            return;
        }
        if (interval.to - interval.from < this.CACHE_LIVE_FETCH_LIMIT && interval.to === liveOffset) {
            return;
        }
        return interval;
    }


    private isCached(channelId: number, time: number) {
        if (!this.broadcastIntervals[ channelId ]) {
            return;
        }
        return this.broadcastIntervals[ channelId ]
            .some(broadcastInterval => broadcastInterval.from <= time && broadcastInterval.to > time);
    }

    private isCacheFull(channelId: number) {
        return this.broadcastGaps[ channelId ] && this.broadcastGaps[ channelId ].length >= this.CACHE_LENGTH_LIMIT_PER_CHANNEL;
    }

    private clearCache(channelId: number) {
        if (this.broadcastIntervals[ channelId ].length === 0) {
            return;
        }
        const middleTime = this.broadcastIntervalsMargins[ channelId ].from +
            (this.broadcastIntervalsMargins[ channelId ].to - this.broadcastIntervalsMargins[ channelId ].from) / 2;
        let deleteInterval: Interval;
        if (this.lastTimeHit[ channelId ] > middleTime) {
            deleteInterval = { from: this.broadcastIntervals[ channelId ][ 0 ].from, to: middleTime };
        } else {
            deleteInterval = { from: middleTime, to: this.broadcastIntervals[ channelId ][ 0 ].to };
        }
        let intervals = [];
        for (const broadcastInterval of this.broadcastIntervals[ channelId ]) {
            // inside, delete
            if (broadcastInterval.from >= deleteInterval.from && broadcastInterval.to <= deleteInterval.to) {
                intervals = [ ...intervals ];
                continue;
            }
            // cross left
            if (broadcastInterval.from < deleteInterval.from && broadcastInterval.to > deleteInterval.from) {
                intervals = [ ...intervals, { from: broadcastInterval.from, to: deleteInterval.from } ];
                continue;
            }
            // cross right
            if (broadcastInterval.from < deleteInterval.to && broadcastInterval.to > deleteInterval.to) {
                intervals = [ ...intervals, { from: deleteInterval.to, to: broadcastInterval.to } ];
                continue;
            }
            // outside
            intervals = [ ...intervals, broadcastInterval ];
        }
        this.broadcastIntervals[ channelId ] = intervals;
        this.recalculateMarginIntervals(channelId);
        this.broadcastGaps[ channelId ] = this.broadcastGaps[ channelId ].filter(item =>
            item.datetimeFrom >= this.broadcastIntervalsMargins[ channelId ].from &&
            item.datetimeTo <= this.broadcastIntervalsMargins[ channelId ].to);
    }

    private recalculateMarginIntervals(channelId: number) {
        if (this.broadcastIntervals[ channelId ].length === 0) {
            this.broadcastIntervalsMargins[ channelId ] = undefined;
        }
        const firstItem = this.broadcastIntervals[ channelId ][ 0 ];
        const lastItem = this.broadcastIntervals[ channelId ][ this.broadcastIntervals[ channelId ].length - 1 ];
        this.broadcastIntervalsMargins[ channelId ] = {
            from: firstItem.from,
            to: lastItem.to
        };
    }

    private fetchBroadcastGaps(channelId: number, interval: Interval, liveOffset: number): Observable<Array<BroadcastGap>> {
        const fetchInterval = this.checkLiveIntervalDuration(this.getFetchInterval(channelId, interval), liveOffset);
        if (fetchInterval) {
            return this.getBroadcastGaps(channelId, fetchInterval)
                .pipe(
                    tap((broadcastGaps) => {
                        if (this.isCacheFull(channelId)) {
                            this.clearCache(channelId);
                        }
                        this.storeBroadcastGaps(channelId, broadcastGaps);
                        this.storeBroadcastInterval(channelId, fetchInterval);
                    })
                );
        }
        return of(undefined);
    }

    private getBroadcastGaps(channelId: number, interval: Interval): Observable<Array<BroadcastGap>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('from', interval.from.toString());
        httpParams = httpParams.append('to', interval.to.toString());
        return this.httpClient
            .get<Array<BroadcastGap>>(`${ environment.apiUrl }broadcast_gaps/${ channelId }`, { params: httpParams });
    }

    // TODO: remove after service debugged
    private debugIntervals(intervals) {
        return intervals.map(item => ({
            from: new Date(item.from),
            to: new Date(item.to)
        }));
    }
}
