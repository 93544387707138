<div class="screen-saver-slide"
     *ngIf="carrouselList?.tiles[activeSlideIndex]; let slide"
     [ngStyle]="getScreenSaverScreenStyles()">
    <div class="screen-saver-title">{{ slide.label }}</div>
    <app-rating [rating]="slide.rating"></app-rating>
    <app-auto-scroll-container class="screen-saver-description"
                               type="vertical">{{ slide.description }}</app-auto-scroll-container>
    <div *ngIf="slide.btnText && slide.btnText.length > 0" class="screen-saver-button button">
        <div class="icon icon-play-button-big"></div>
        <div class="button-label">{{ slide.btnText }}</div>
    </div>
</div>
