import { Injectable, NgZone } from '@angular/core';
import { HalFeature, PlatformInfo } from '@kuki/global/shared/types/general';
import { PlatformHal } from '@kuki/platforms/platform-hal';
import { toiVersion } from './toi-version';
import { Observable, of } from 'rxjs';
import { hal } from '@kuki/platforms/hal';
import { HttpClient } from '@angular/common/http';
import { catchError, timeout } from 'rxjs/operators';
import { BootModeTypes } from '@kuki/global/shared/types/enum';

declare var toi;

@Injectable()
export class ArrisPlatformHalService implements PlatformHal {

    private readonly watchDogPingUrl: string = 'http://127.0.0.1:7744/watchdog';
    private readonly platformInfoUrl: string = 'http://127.0.0.1:7744/platform-info.json';
    private readonly HEVCsupportModels = [ 'VIP4302' ];

    private gcInterval: any;
    private fwVersionBase: string;
    private sn: string;
    private isObjectCache: any = {};
    private powerControl: any;

    private features: HalFeature;
    private videoModes: Array<number>;
    private videoModeDefault: number = null;
    private audioOut: any;
    private muteLocked: boolean;
    private muteLockedState: boolean;
    private readonly videoModesConf = [
        toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_576P50,
        toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_720P50,
        toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080I50,
        toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080P25,
        toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080P50
    ];

    private readonly videoModesModelsConf = {
        'VIP4205': [
            toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_576P50,
            toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_720P50,
            toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080I50,
            toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080P50
        ],
        'VIP4302': [
            toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_576P50,
            toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_720P50,
            toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080I50,
            toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080P50
        ]
    };
    private readonly videoModesTexts = {
        [ toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_576P50 ]: '576p50',
        [ toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_720P50 ]: '720p50',
        [ toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080I50 ]: '1080i50',
        [ toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080P25 ]: '1080p25',
        [ toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080P50 ]: '1080p50'
    };
    private readonly videoModeDefaultConf = toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_1080P50;
    private readonly videoModeDefaultModelsConf = {
        'vip1003': toi.consts.ToiVideoOutputConfiguration.VIDEO_MODE_720P50
    };

    public deviceReady$: Observable<void> = of(null);
    private readonly oldObjectNames: Array<string> = [ 'cfg.locale.timezone' ];

    constructor(
        private ngZone: NgZone,
        private httpClient: HttpClient) {
    }

    public init(localPortal: boolean = false) {
        this.fwVersionBase = this.loadFwVersionBase();
        if (localPortal) {
            return;
        }
        this.ngZone.runOutsideAngular(() => {
            this.gcInterval = setInterval(() => {
                if ((window as any).gc) {
                    (window as any).gc();
                    console.log('gc manual trigger!');
                }
            }, 10000);
        });
        this.findAudioOut();
        this.setApd(0);
        this.clearOldObjects();
    }

    public getSerial() {
        this.sn = this.sn || this.getIsObject('const.hw.serialnumber');
        return this.sn;
    }

    public getIp(): string {
        try {
            return this.getIsObject('var.ip.eth0.addr');
        } catch (e) {
            try {
                return this.getIsObject('cfg.ip.eth0.addr');
            } catch (e) {
                return;
            }
        }
    }

    public getMac(): string {
        if (toiVersion >= 3) {
            return this.getIsObject('internal.const.ip.eth0.mac');
        } else {
            return this.getIsObject('const.ip.eth0.mac');
        }
    }

    public getProductName(): string {
        const dta = this.getIsObject('nbx.devicetypeadd');
        let pn = this.getIsObject('const.hw.productname');
        if (dta) {
            pn = `${ pn }${ dta }`;
        }
        return pn;
    }

    public getVersionFw(): string {
        return this.getIsObject('const.sw.version');
    }

    public getFwVersionBase(): string {
        return this.fwVersionBase;
    }

    public getBootMode(): BootModeTypes {
        if (this.getIsObject('_metachannel.utctime')) {
            return BootModeTypes.MULTICAST;
        }
        return BootModeTypes.UNICAST;
    }

    public getDeviceModel(): string {
        return this.getIsObject('const.hw.productname');
    }

    public getClaimedDeviceId(): string {
        return this.getSerial();
    }

    public getPlatformInfo(): Observable<PlatformInfo> {
        return this.httpClient.get<PlatformInfo>(this.platformInfoUrl).pipe(timeout(10000), catchError(() => {
            console.log('Can\'t fetch platformInfo, using zeros as defaults');
            return of({
                mem_total: 0,
                mem_free: 0,
            });
        }));
    }

    public restart(): void {
        if (toiVersion === 2) {
            toi.applicationService.kill(toi.applicationService.getOwnApplicationId());
        } else {
            toi.applicationService.getPrimaryApplication().stop();
        }
    }

    public reboot(): void {
        toi.platformService.rebootNow();
    }

    public powerOff(reason: string = toi.consts.ToiPowerControl.REASON_AUTO_POWER_DOWN): void {
        if ((toiVersion === 2 && this.fwVersionBase === '4.10') || toiVersion === 3) {
            this.powerControl.requestPowerProfile(
                toi.consts.ToiPowerControl.PROFILE_ACTIVE_STANDBY, reason);
        } else {
            toi.platformService.setStandby(true);
        }
    }

    public powerOn(): void {
        if ((toiVersion === 2 && this.fwVersionBase === '4.10') || toiVersion === 3) {
            this.powerControl.requestPowerProfile(
                toi.consts.ToiPowerControl.PROFILE_ACTIVE, toi.consts.ToiPowerControl.REASON_STANDARD);
        } else {
            toi.platformService.setStandby(false);
        }
    }

    public checkConnection(): string {
        if (toiVersion === 2) {
            if (this.fwVersionBase === '4.10') {
                const configuration = toi.netService.getConfiguration();
                const base_device = configuration.getNetworkDevices(configuration.NET_DEVICE_TYPE_ETHERNET)[ 0 ];
                if (configuration.getGenericDeviceInfo(base_device).isReady !== true) {
                    return 'fail';
                }
            } else {
                if (this.getIsObject('var.ip.eth0.status') !== 'Valid') {
                    return 'fail';
                }
            }
        } else {
            if (toi.netService.getNetwork(toi.consts.ToiNetService.BOOT_NETWORK).getNetworkInfo().state !==
                toi.consts.ToiNetwork.STATE_READY) {
                return 'fail';
            }
        }
        return 'ok';
    }

    public getWatchDogPingUrl() {
        return this.watchDogPingUrl;
    }

    public getDeviceState() {
        return this.getIsObject('var.io.state');
    }

    public isStandBy() {
        return this.getDeviceState() === 'standby';
    }

    public setIsObject(name, value) {
        this.isObjectCache[ name ] = value.toString();
        if (toiVersion === 3) {
            toi.informationService.set({
                'name': name,
                'value': value.toString()
            }, toi.informationService.STORAGE_PERMANENT);
        } else {
            toi.informationService.setObject(name, value.toString(), toi.informationService.STORAGE_PERMANENT);
        }
    }

    public getIsObject(name: string, def = null) {
        let r;
        if (toiVersion >= 3) {
            const x = toi.informationService.get(name);
            if (x[ 0 ].status !== toi.informationService.STATUS_OK) {
                r = undefined;
            } else {
                r = x[ 0 ].objectItem.value;
            }
        } else {
            try {
                r = toi.informationService.getObject(name);
            } catch (e) {
                if (e.name === 'TToiInvalidArgumentException') {
                    r = undefined;
                }
            }
        }
        if (r === undefined && def !== null) {
            return def;
        }
        return r;
    }

    public clearIsObject(name: string) {
        try {
            if (toiVersion >= 3) {
                toi.informationService.unset(name, toi.informationService.STORAGE_PERMANENT);
            } else {
                toi.informationService.unsetObject(name, toi.informationService.STORAGE_PERMANENT);
            }
        } catch (e) {
            console.error(e);
        }
    }

    public storeSettingsValue(key: string, value: string) {
        return this.setIsObject('nbx.settings.' + key, value);
    }

    public loadSettingsValue(key: string) {
        return this.getIsObject('nbx.settings.' + key);
    }

    public getIsObjectCached(name, def = null) {
        if (!this.isObjectCache[ name ]) {
            this.isObjectCache[ name ] = this.getIsObject(name, def);
        }
        return this.isObjectCache[ name ];
    }

    public getDefaultVolume() {
        const dv = parseInt(this.getIsObject('nbx.volume', hal.volume?.defaultVolume), 10);
        if (dv < hal.volume.volumeMin) {
            return hal.volume.volumeMin;
        }
        if (dv > hal.volume.volumeMax) {
            return hal.volume.volumeMax;
        }
        return dv;
    }

    public getDefaultMute() {
        return this.getIsObject('nbx.mute', hal.volume.defaultMute ? '1' : '0') === '1';
    }

    public setVolume(volume, audioOut = this.audioOut) {
        this.setIsObject('nbx.volume', volume);
        try {
            toi.audioOutputService.setVolume(audioOut, volume);
        } catch (e) {
            console.log('Unable to set volume: ' + e);
        }
    }

    public setMute(mute, lock: boolean = false, audioOut = this.audioOut) {
        // already locked, just change state
        if (this.muteLocked) {
            this.muteLockedState = mute;
            console.log('Mute is locked');
            return;
        }
        if (lock) {
            this.lockMute();
        } else {
            this.setIsObject('nbx.mute', mute ? '1' : '0');
        }
        try {
            toi.audioOutputService.setMuteState(audioOut, mute);
        } catch (e) {
            console.log('Unable to set mute:' + e);
        }
    }

    public lockMute() {
        this.muteLocked = true;
        this.muteLockedState = this.getIsObjectCached('nbx.mute', hal.volume.defaultMute ? '1' : '0') === '1';
    }

    public unlockMute() {
        this.muteLocked = false;
        if (this.muteLockedState !== undefined) {
            this.setMute(this.muteLockedState);
        }
        this.muteLockedState = undefined;
    }

    // 1 - original
    // 2 - fill
    public getDefaultAdaptiveModification() {
        if (this.loadSettingsValue('scaleMode') === '2') {
            return 'anamorphic';
        } else {
            return '';
        }
    }

    public reloadAdaptiveRules(modification = null, retry = 0) {
        console.log('reloadAdaptiveRules');
        let res, scale;
        if (this.loadSettingsValue('aspect') === '1') {
            res = '4:3';
        } else {
            res = '16:9';
        }
        if (modification !== null) {
            scale = modification.toString();
        } else {
            scale = this.getDefaultAdaptiveModification();
        }
        if (scale !== '') {
            scale = ` - ${ scale }`;
        }
        const adaptive = `NETBOX${ scale } ${ res }`;
        console.log(JSON.stringify([ `${ adaptive } HD`, `${ adaptive } SD` ]));
        try {
            toi.videoOutputService.loadAdaptiveRuleSets([ `${ adaptive } HD`, `${ adaptive } SD` ]);
        } catch (e) {
            console.error(e);
            console.log('Reload Adaptive Rules failed, try again');
            if (retry < 5) {
                setTimeout(() => {
                    this.reloadAdaptiveRules(modification, retry + 1);
                }, 500);
            } else {
                console.error('Unable to reload adaptive rules');
            }
        }
    }

    public getFeatures() {
        if (!this.features) {
            this.features = {
                hevc: this.HEVCsupportModels.indexOf(this.getDeviceModel()) >= 0,
                wv: false,
            };
            if (this.getDeviceModel() === 'vip1003') {
                this.features.maxLumaSamplesPerSec = 80000000;
            } else {
                this.features.maxLumaSamplesPerSec = 1920 * 1080 * 60;
            }
        }
        console.log('features');
        console.log(JSON.stringify(this.features));
        return this.features;
    }

    public getVideoModes(): Array<number> {
        if (!this.videoModes) {
            const prod = this.getIsObject('const.hw.productname');
            this.videoModes = this.videoModesModelsConf[ prod ] || this.videoModesConf;
        }
        return this.videoModes;
    }

    public getDefaultVideoMode(): number {
        if (this.videoModeDefault === null) {
            const prod = this.getIsObject('const.hw.productname');
            this.videoModeDefault = this.videoModeDefaultModelsConf[ prod ] || this.videoModeDefaultConf;
        }
        return this.videoModeDefault;
    }

    public getVideoModeText(videoMode: number) {
        return this.videoModesTexts[ videoMode ];
    }

    private setApd(adpTimeout) {
        if (toiVersion === 3) {
            toi.informationService.set({
                'name': 'cfg.power.apd.timeout',
                'value': `${ adpTimeout }`
            }, toi.informationService.STORAGE_PERMANENT);
        } else {
            toi.informationService.setObject(
                'cfg.power.apd.timeout',
                `${ adpTimeout }`,
                toi.informationService.STORAGE_PERMANENT);
        }
    }

    private getSwVersion() {
        if (toiVersion === 3) {
            return toi.informationService.get('const.sw.version')[ 0 ].objectItem.value;
        } else {
            return toi.informationService.getObject('const.sw.version');
        }
    }

    private loadFwVersionBase() {
        return this.getSwVersion().split('.').slice(0, 2).join('.');
    }

    private findAudioOut() {
        const connectionType = toiVersion === 3 ?
            toi.audioOutputService.AUDIO_CONNECTION_TYPE_HDMI : toi.audioOutputService.AUDIO_CONNECTION_TYPE_DECODER;
        toi.audioOutputService.getConnections().forEach((c) => {
            if (c.type === connectionType) {
                this.audioOut = c.id;
                return;
            }
        });
    }

    public getPowerControl() {
        if (!this.powerControl) {
            if ((toiVersion === 2 && this.fwVersionBase === '4.10') || toiVersion === 3) {
                this.powerControl = toi.platformService.createPowerControlInstance(toi.consts.ToiPowerControl.ACCESS_REPORT_AND_REQUEST);
            } else {
                this.powerControl = toi.platformService.createPowerControlInstance(toi.consts.ToiPowerControl.ACCESS_REPORT_ONLY);
            }
        }
        return this.powerControl;
    }

    public destroy() {
        if (this.gcInterval) {
            clearInterval(this.gcInterval);
        }
    }

    private clearOldObjects() {
        if (!this.oldObjectNames) {
            return;
        }
        this.oldObjectNames.forEach((key) => {
            this.clearIsObject(key);
        });
    }
}

